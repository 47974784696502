import React from 'react';
import Loader from 'react-loader-spinner';
import { gnbColorPalette } from '../../gnb-theme';
import { Backdrop } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 2500,
    color: '#fff',
  },
}));

const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <Backdrop data-test-id="loading-spinner" className={classes.backdrop} open={true}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: -45,
            marginTop: -45,
            zIndex: 5000,
          }}
        >
          <Loader type="Oval" color={gnbColorPalette.dark.secondaryMedium} height={90} />
        </div>
      </div>
    </Backdrop>
  );
};

export default LoadingSpinner;
